import styled from 'styled-components';

interface FillProps {
  $percent: string;
}

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
`;

interface BarProps {
  $markerPercent: string;
}

export const Bar = styled.div<BarProps>`
  box-sizing: border-box;
  background-color: #f0e8f1;
  border-radius: 8px;
  position: relative;
  height: 16px;
  padding: 4px;

  &:after {
    box-sizing: border-box;
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: ${({ $markerPercent }) => $markerPercent}%;
    border-left: 1px dashed red;
  }
`;

export const Fill = styled.div<FillProps>`
  background-color: #833c8c;
  border-radius: 8px;
  height: 8px;
  width: ${({ $percent }) => $percent}%;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
