import React from 'react';

import { Text } from '@constellation/core';

import {
  LabelContainer,
  Bar,
  Fill,
  FlexContainer,
} from './PercentageIndicator.styled';

interface PercentageIndicatorProps {
  markerPercent: string;
  percent: string;
  'data-testid'?: string;
}

const clampPercent = (percent: string) => {
  const parsedPercent = parseFloat(percent);

  return Math.max(0, Math.min(100, parsedPercent)).toString();
};

function PercentageIndicator({
  markerPercent,
  percent,
  'data-testid': testId,
}: PercentageIndicatorProps) {
  return (
    <div data-testid={testId}>
      <FlexContainer>
        <Text
          marginBottom="01"
          size="s5"
          weight="bold"
          data-testid={`${testId}-percent-label`}
        >
          {percent}%
        </Text>
      </FlexContainer>
      <Bar $markerPercent={clampPercent(markerPercent)}>
        <Fill $percent={clampPercent(percent)} />
      </Bar>
      <LabelContainer>
        <Text weight="bold" size="s1">
          0
        </Text>
        <Text weight="bold" size="s1" style={{ transform: 'translateX(50%)' }}>
          50
        </Text>
        <Text weight="bold" size="s1">
          100
        </Text>
      </LabelContainer>
    </div>
  );
}

export default PercentageIndicator;
