import { AppContent } from 'content/AppContent';

const mortgageOverview: AppContent['mortgageOverview'] = {
  title: 'Your balance',
  notifications: {
    heading: 'Notifications',
    switchNotification: {
      heading: "It's time to switch your deal",
      supportiveText: 'A new deal could save you money.',
    },
    futureFeaturesNotification: {
      line1: 'Manage your mortgage online now',
      line2: "We'll be rolling out more features soon.",
      linkText: 'Let us know what you think.',
    },
  },
  welcomeMessage: 'Welcome',
  propertyPanel: {
    propertyValueLabel: 'Estimated Property Value',
    sincePurchaseLabel: ' since purchase',
  },
  loanToValuePanel: {
    heading: 'Loan to Value',
    equityLabel: 'Estimated Equity',
    balanceLabel: 'Loan Balance',
    whatIsLTVModal: {
      label: 'What is Loan to Value?',
      heading: 'Loan to Value',
      supportiveText:
        'Loan to value (LTV) is the percentage of borrowing you take out against your home. For example, if you have a £150,000 mortgage on a £200,000 house, the loan to value would be 75%.',
    },
    borrowMoreCTA: {
      url: '/borrow-more',
      heading: 'Borrow more',
      supportiveText: 'Our maximum LTV is 80%.',
    },
  },
  overpaymentsPanel: {
    title: 'Your overpayment allowance',
    allowanceAmountText: '10%.',
    supportiveTextPara1:
      'This is how much you can overpay your mortgage in a calendar year without having to pay any early repayment charges (ERCs).',
    supportiveTextPara2:
      'If you have a fixed or tracker mortgage, your overpayment allowance is',
    supportiveTextPara3:
      'If you have a variable rate mortgage, you can overpay as much as you like without paying any ERCs.',
    supportiveTextPara4: 'Please check your mortgage offer.',
    overpaymentLink: {
      text: 'How to make an overpayment',
      url: '/overpayments',
    },
  },
  redemptionPanel: {
    title: 'Paying off your mortgage',
    supportiveText:
      'Find out how much paying your mortgage off in full could cost.',
    link: {
      text: 'Find out the cost',
      url: '/redemption',
    },
  },
  rentalYieldPanel: {
    title: 'Rental yield calculator',
    supportiveText:
      'Rental yield helps you figure out how much income you can earn from investing in a rental property.',
    linkLabel: 'Calculate',
  },
  mortgageInfoPanel: {
    accountNumberLabel: 'Mortgage account number',
    mortgageTermLabel: 'Remaining mortgage term',
    interestRatesLabel: 'Interest rates',
    subAccountsLabel: ' sub-accounts',
    subAccountsModal: {
      label: 'What are sub-accounts?',
      heading: 'Sub-accounts',
      supportiveText:
        'Your mortgage could be a mix of different repayment methods with different interest rates over different mortgage terms. For example, you could have individual balances for additional borrowing or fees. These are known as sub-accounts. Each sub-account has its own individual monthly payment, which we add together to make up your total monthly mortgage payment.',
    },
    monthlyPaymentLabel: 'Current monthly payment',
    nextPaymentLabel: 'Next payment due',
  },
  requestsPanel: {
    heading: 'Requests',
    certificateOfInterestLink: {
      text: 'Certificate of Mortgage Interest',
      url: '/requests/certificate-of-interest',
    },
    changeOfDirectDebitLink: {
      text: 'Change of Direct Debit due date',
      url: '/requests/change-direct-debit',
    },
    overpaymentsAllowanceLink: {
      text: 'Overpayments allowance',
      url: '/requests/overpayments-allowance',
    },
    redemptionStatementLink: {
      text: 'Redemption statement',
      url: '/requests/redemption-statement',
    },
    duplicateStatementLink: {
      text: 'Duplicate statement',
      url: '/requests/duplicate-statement',
    },
  },
  energyEfficiencyPanel: {
    text: "Improve your property's energy efficiency",
    link: {
      text: 'Get your Action Plan',
      url: 'https://home-energy-saving-tool.bmsolutions.co.uk/wheredoyoulive',
    },
  },
  feedbackPanel: {
    text: "We'd love to hear what you think",
    link: {
      text: 'Give us your feedback',
      url: 'https://lloydsbankinggroup.eu.qualtrics.com/jfe/form/SV_07lOersw6HAG5tY',
    },
  },
  paymentHelpPanel: {
    heading: 'Worried about making payments?',
    helpNumber: '0808 145 0372',
    paragraph1:
      "If you're worried about making future payments, please get in touch.",
    paragraph2: 'Call',
    paragraph3:
      'Lines are open Monday to Friday 8am - 8pm and Saturday 9am - 1pm.',
    paragraph4: "We're closed Sundays and bank holidays.",
    paragraph5: 'The sooner you contact us, the sooner we can help.',
  },
  propertyValue: {
    title: 'Estimated Property Value',
    paragraph1: `We’ve based this on our valuation from local market data.`,
    paragraph2: `Your home's true value might be higher or lower than the figure shown. This will depend on your home’s condition and if you've made any changes we don't know about.`,
    okayButtonLabel: 'Okay',
  },
  whatsNewDialog: {
    versionNumber: 'v1',
    cookieName: 'whatsNewVersion',

    title: 'New tools to manage your property',
    icons: ['Home'],
    subheadings: ['Paying off your mortgage', 'Rental yield calculator'],
    paragraphs: [
      'See how much it would cost to pay off your mortgage in full.',
      "Find out your return on investment. Enter your property value and rental income and we'll tell you your rental yield percentage.",
    ],
    buttonLabel: 'Got it',
  },
};

export default mortgageOverview;
