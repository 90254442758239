import { AppContent } from 'content/AppContent';

const signIn: AppContent['signIn'] = {
  title: 'Sign in',
  fields: {
    accountNumber: {
      label: '14-digit mortgage account number',
      supportiveText:
        "This number starts with '60' and you'll find it on your mortgage statement.",
      error: 'Please enter a valid 14 - digit mortgage account number',
      summaryError: 'Please enter a valid 14 - digit mortgage account number',
      showMeWhereText: 'Show me where',
    },
    dob: {
      label: 'Date of birth',
      error: 'Please enter a valid date of birth',
      summaryError: 'Please enter your date of birth',
    },
    postcode: {
      label: 'Postcode of the mortgaged property',
      supportiveText:
        "If you're a landlord, this is the postcode for the property you rent out.",
      error: 'Please enter a valid UK postcode',
      summaryError: 'Please enter a postcode, for example SW1A 1AA',
    },
    summaryErrors: {
      defaultTitle: "Something's not quite right",
      invalidTitle: "We can't find a match for the details you've entered.",
      invalidFirstParagraph:
        'Please check that everything is correct and try again.',
      invalidSecondParagraph:
        "If you have any more problems entering your details or can't find your mortgage account number, please call us on ",
      invalidThirdParagraph:
        "Lines are open Monday to Friday 8am - 8pm, Saturday 9am - 1pm. We're closed on Sundays and Bank Holidays.",
      helpPhoneNumber: '0345 300 2627',
    },
  },
  signInButtonLabel: 'Sign in',
  signOutNotificationText: "We've signed you out to keep your account safe.",
  applicationHours: {
    title: 'Application hours',
    mainText:
      'So we can provide you with the best possible service, this online application is only available during these times:',
    monToSatHours: 'Monday - Saturday: 6am - 10pm',
    sunHours: 'Sunday: 6am - 9pm',
  },
  showMeWhereDialog: {
    paragraph:
      "You'll find your 14-digit mortgage account number on the right-hand side of your mortgage statement. It's underneath our address.",
    confirmText: 'Got it',
  },
};

export default signIn;
